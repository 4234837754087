// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-template-js": () => import("/Users/sawa/Projects/thebrain_website_gatsby/src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("/Users/sawa/Projects/thebrain_website_gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/Users/sawa/Projects/thebrain_website_gatsby/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-study-js": () => import("/Users/sawa/Projects/thebrain_website_gatsby/src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-index-js": () => import("/Users/sawa/Projects/thebrain_website_gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("/Users/sawa/Projects/thebrain_website_gatsby/src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-team-js": () => import("/Users/sawa/Projects/thebrain_website_gatsby/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-testimonials-js": () => import("/Users/sawa/Projects/thebrain_website_gatsby/src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

exports.data = () => import("/Users/sawa/Projects/thebrain_website_gatsby/.cache/data.json")

