module.exports = [{
      plugin: require('/Users/sawa/Projects/thebrain_website_gatsby/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":740,"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/sawa/Projects/thebrain_website_gatsby/node_modules/gatsby-plugin-intercom-spa/gatsby-browser'),
      options: {"plugins":[],"app_id":"yndcllpy"},
    },{
      plugin: require('/Users/sawa/Projects/thebrain_website_gatsby/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser'),
      options: {"plugins":[],"pixelId":"301972793743307"},
    },{
      plugin: require('/Users/sawa/Projects/thebrain_website_gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
